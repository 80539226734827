import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import SellerDetails from "./Components/sellerDetails/Table";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";
import EmployeeRequest from "./Components/EmployeeManagement/EmployeeRequests/Table";
import EmployeeRejected from "./Components/EmployeeManagement/EmployeeRejected/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";

import Product from "./Components/Products/Product/Table";
import ProductRequests from "./Components/Products/ProductRequests/Table";
import WhyChooseUs from "./Components/AboutUs/WhyChooseUs/Tables";
import HappyCustomers from "./Components/AboutUs/HappyCustomers/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";

import Orders from "./Components/OrderManagments/Orders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";

// Settings 
// Categories
import Category from "./Components/Masters/Category/Tables"
import SubCategory from "./Components/Masters/Sub_Category/Tables"
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables"
import Attributes from "./Components/Masters/Attribute/Tables"
import SubAttributes from "./Components/Masters/SubAttribute/Tables"

// Customization 
import WebHeader from "./Components/Masters/WebHeader/Tables"
import AppSetup from "./Components/Masters/AppSetup/Tables"
import HomeBanner from "./Components/Masters/S_Banner/Tables"
import HomeAdBanner from "./Components/Masters/Ad_Banner/Tables"
import Blogs from "./Components/Masters/Blogs/Tables"
import FaqCategory from "./Components/Masters/FaqCategory/Tables"
import Faq from "./Components/Masters/FAQs/Tables"
import CategoryBanner from "./Components/Masters/category_Banner/Tables"

import CouponCodes from "./Components/Masters/CouponCodes/Table"
import Brands from "./Components/Masters/Brands/Tables"

// Location 
import Country from "./Components/Masters/Country/Tables"
import State from "./Components/Masters/State/Tables"
import City from "./Components/Masters/City/Tables"
import Pincode from "./Components/Masters/Pincode/Tables"
import CountryCodes from "./Components/Masters/CountryCode/Tables"

// Tax 
import TaxType from "./Components/Masters/Tax_Types/Tables"
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables"


//Content
import Privacy from "./Components/Content/Privacy/Tables"
import Terms from "./Components/Content/Terms/Tables"
import PageNotFound from '../src/Components/PageNotFound/PageNotFound';
import { IDS } from "./utils/common";
// Seller

const App = () => {
  const location = useLocation();
  const { isSidebarOpen ,isAllow} = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([

      {
        path: "/",
        element: <Login />,
        status: true,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/settingContent",
        element: <SettingContent />,
        status: true,
      },
      {
        path: "/employee/role",
        element: <Role />,
        status: isAllow.includes(IDS.Role.List),
      },
      {
        path: "/seller-detail",
        element: <SellerDetails />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-details",
        element: <EmployeeDetails />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-requests",
        element: <EmployeeRequest />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-rejected",
        element: <EmployeeRejected />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/employee/customer-details",
        element: <ContactUs />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/employee/contact-us",
        element: <ContactUs />,
        status: isAllow.includes(IDS.ContactUs.List),
      },
      {
        path: "/product",
        element: <Product />,
        status: isAllow.includes(IDS.Product.List),
      },
      {
        path: "/product-requests",
        element: <ProductRequests />,
        status: isAllow.includes(IDS.Product.List),
      },
      {
        path: "/product/:id",
        element: <Product />,
        status: isAllow.includes(IDS.Product.List),
      },
      {
        path: "/about-us/why-choose-us",
        element: <WhyChooseUs />,
        status: isAllow.includes(IDS.WhyChooseUs.List),
      },
      {
        path: "/about-us/happy-customers",
        element: <HappyCustomers />,
        status: isAllow.includes(IDS.HappyCustomer.List),
      },
      {
        path: "/about-us/our-story",
        element: <OurStory />,
        status: isAllow.includes(IDS.OurStory.List),
      },
      {
        path: "/about-us/about-banner",
        element: <AboutBanner />,
        status: isAllow.includes(IDS.AboutBanner.List),
      },
      {
        path: "/content/privacy",
        element: <Privacy />,
        status: isAllow.includes(IDS.PrivacyPolicy.List),
      },
      {
        path: "/content/terms",
        element: <Terms />,
        status: isAllow.includes(IDS.Terms.List),
      },
      {
        path: "/why_choose_us",
        element: <WhyChooseUs />,
        status: isAllow.includes(IDS.WhyChooseUs.List),
      },
      {
        path: "/order-managements/orders",
        element: <Orders />,
        status: isAllow.includes(IDS.Order.List),
      },
      {
        path: "/order-managements/order/refund",
        element: <OrdersToRefund />,
        status: isAllow.includes(IDS.Order.List),
      },
      {
        path: "/order-managements/order/return",
        element: <ReturnOrders />,
        status: isAllow.includes(IDS.Order.List),
      },

      {
        path: "/settings/category",
        element: <Category />,
        status: isAllow.includes(IDS.Category.List),
      },
      {
        path: "/settings/sub-category",
        element: <SubCategory />,
        status: isAllow.includes(IDS.SubCategory.List),
      },
      {
        path: "/settings/child-sub-category",
        element: <ChildSubCategory />,
        status: isAllow.includes(IDS.ChildSubCategory.List),
      },
      {
        path: "/settings/attributes",
        element: <Attributes />,
        status: true,
      },
      {
        path: "/settings/sub-attributes",
        element: <SubAttributes />,
        status: true,
      },
      {
        path: "/settings/customization/web-header",
        element: <WebHeader />,
        status: isAllow.includes(IDS.WebHeader.List),
      },
      {
        path: "/settings/customization/app-setup",
        element: <AppSetup />,
        status: isAllow.includes(IDS.AppSetup.List),
      },
      {
        path: "/settings/customization/home-banner",
        element: <HomeBanner />,
        status: isAllow.includes(IDS.Banner.List),
      },
      {
        path: "/settings/customization/home-ad-banner",
        element: <HomeAdBanner />,
        status: isAllow.includes(IDS.AdvertisementBanner.List),
      },
      {
        path: "/settings/customization/category-banner",
        element: <CategoryBanner />,
        status: isAllow.includes(IDS.CategoryBanner.List),
      },
      {
        path: "/settings/customization/blogs",
        element: <Blogs />,
        status: isAllow.includes(IDS.Blogs.List),
      },
      {
        path: "/settings/customization/faq-category",
        element: <FaqCategory />,
        status: isAllow.includes(IDS.FAQCategory.List),
      },
      {
        path: "/settings/customization/faqs",
        element: <Faq />,
        status: isAllow.includes(IDS.FAQ.List),
      },
      {
        path: "/settings/Coupon-codes",
        element: <CouponCodes />,
        status: isAllow.includes(IDS.CouponCode.List),
      },
      {
        path: "/settings/brands",
        element: <Brands />,
        status: isAllow.includes(IDS.Brands.List),
      },

      {
        path: "/settings/location/country",
        element: <Country />,
        status: isAllow.includes(IDS.Country.List),
      },
      {
        path: "/settings/location/state",
        element: <State />,
        status: isAllow.includes(IDS.State.List),
      },
      {
        path: "/settings/location/city",
        element: <City />,
        status: isAllow.includes(IDS.City.List),
      },
      {
        path: "/settings/location/pincode",
        element: <Pincode />,
        status: isAllow.includes(IDS.Pincode.List),
      },
      {
        path: "/settings/location/country-codes",
        element: <CountryCodes />,
        status: isAllow.includes(IDS.CountryCode.List),
      },

      {
        path: "/settings/tax/tax-type",
        element: <TaxType />,
        status: isAllow.includes(IDS.TaxType.List),
      },
      {
        path: "/settings/tax/tax-percentage",
        element: <TaxPercentage />,
        status: isAllow.includes(IDS.TaxPercentage.List),
      },

    ])
  }, [isAllow]);
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${isSidebarOpen
              ? "sidebarHeadopen "
              : "scale-in-hor-left sidebarHeadClose"
              }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${isSidebarOpen
              ? "dashboradopen"
              : "scale-in-hor-right dashboradopen"
              }  ps-0`}
          >
            <div className="allRoutesMain">
            <Routes>
                {urls?.map(
                  (url) => url.status && <Route path={url.path} element={url.element} />
                )}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
